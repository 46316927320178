import React, {
  useCallback, useEffect, useRef,
} from 'react';
import { useForm } from 'react-hook-form';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import slugify from 'slugify';

import { InputText, TextEditor } from '../../lib/HooksFormFields';
import CodeEditor from '../../lib/HooksFormFields/CodeEditor';
import InputMedia from '../../lib/HooksFormFields/InputMedia';
import PositionalModal from '../../lib/PositionalModal';
import { Field } from '../../types/page';

import styles from './ObjectArray.module.scss';

const Item = ({
  item,
  fields,
  id,
  index,
  array,
  label = null,
  className,
  blocks,
  parentName,
  handleSubmit,
  handleChangeArray,
  addObject,
} : {
  item: any,
  fields: any,
  id: string,
  index: number,
  array: any[],
  blocks?: { label: string, block: string }[],
  label?: string | null,
  parentName: string,
  className?: string,
  handleSubmit: (value: any) => void,
  handleChangeArray: (value: any) => void,
  addObject: (value: string, index: number) => void,
}) => {
  const itemRef = useRef<any>(`item-${id}`);
  const modalRef = useRef<any>(`item-modal-${id}`);
  const { page } = useSelector((store: any) => store).pagesReducer;
  const {
    getValues,
    control,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: item,
  });

  useEffect(() => {
    reset(item);
  }, [item]);

  useEffect(() => {
    if (isDirty) {
      const data = [...array];
      data[index] = watch();
      handleChangeArray(data);
    }
  }, [isDirty]);

  const handleChangeMedia = useCallback((val: any) => {
    const data = [...array];
    data[index] = { ...watch(), ...val };
    handleChangeArray(data);
  }, [index, array]);

  return <div className={styles.item}>
    {label && <h3>{label} {index + 1}</h3>}
    <div className={`${styles.fields} ${className ? styles[className] : ''}`}>
      {fields.map((field: Field) => (<div key={`${id}-${field.name}`} className={styles.field}>
          {field.type === 'text'
            && <>
              <InputText
                label={field.label ? `${field.label} ${label ? index + 1 : ''}` : null}
                placeholder={field.placeholder || ''}
                name={field.name}
                control={control}
                heading={field.heading}
                rules={{ }}
                className='discret'
              />
              {field.name === 'h3' && watch(field.name) && slugify(watch(field.name))

                && <a className={styles.tag} href={`${process.env.REACT_APP_FRONT_URL}/blog-bakamag/${page.slug}?tag=${slugify(watch(field.name))}`} target="_blank" rel="noreferrer">
                  Lien vers le paragraphe
                </a>
              }

            </>
          }
          {field.type === 'link'
            && <>
                <div className={styles.link}>
                  <label>Lien</label>
                  <div className={styles['col-2']}>
                    <div className={styles.field}>
                      <InputText
                        placeholder={'Label du lien'}
                        name={`${field.name}.label`}
                        control={control}
                        heading={field.heading}
                        rules={{ }}
                        className='discret'
                      />
                    </div>
                    <div className={styles.field}>
                      <InputText
                        placeholder={'http://www....'}
                        name={`${field.name}.url`}
                        control={control}
                        heading={field.heading}
                        rules={{ }}
                        className='discret'
                      />
                    </div>
                  </div>

                </div>
            </>
          }
          {field.type === 'code'
            && <>
              <CodeEditor
                label={field.label ? `${field.label} ${label ? index + 1 : ''}` : null}
                placeholder={field.placeholder || ''}
                name={field.name}
                control={control}
                rules={{ }}
                className='discret'
              />
            </>
          }
          {field.type === 'richtext'
            && <>
              <TextEditor
                label={field.label ? `${field.label} ${label ? index + 1 : ''}` : null}
                placeholder={field.placeholder || ''}
                name={field.name}
                control={control}
                rules={{ }}
                defaultValue={getValues(field.name) || ''}
                maxlength={field.maxlength}
                discret
              />
            </>
          }
          {field.type === 'media'
            && <div className={styles.media}>
              <InputMedia
                // label={field.label}
                name={field.name}
                media={getValues(field.name)?.path && getValues(field.name)}
                // handleChange={(val: any) => {
                //   if (!val) handleDeleteMedia(field.name);
                // }}
                submit={(obj, key, fileId) => {
                  handleChangeMedia(obj);
                  const formKey = `${parentName}.${index}.${key}`;
                  handleSubmit({ [formKey]: fileId });
                }}
              />
            </div>
          }
        </div>
      ))}
    </div>
    <div className={styles.containerButtons}>
      <button
        type='button'
        className={styles.delete}
        onClick={() => {
          handleSubmit({ [parentName]: array.filter((a, i) => i !== index) });
        }}
      >
        <RiDeleteBin2Fill />
      </button>
      {blocks && blocks?.length > 0 && (
        <>
          <button
            type='button'
            ref={itemRef}
            className={styles.delete}
            onClick={() => modalRef.current.open()}
          >
            +
          </button>
          <PositionalModal
            ref={modalRef}
            parent={itemRef}
          >
            <div className={styles.blockOptions}>
              {blocks.map((d) => (
                <button
                  key={`button-${d.block}`}
                  className={styles.btn}
                  onClick={() => addObject(d.block, index)}
                >
                  {d.label}
                </button>
              ))}
            </div>
          </PositionalModal>
        </>
      )}
    </div>
  </div>;
};

export default function ObjectArray({
  handleChangeArray,
  handleSubmit,
  array = [],
  label,
  create,
  fields,
  name,
  item,
  className,
  blocks,
}: {
  label?: string | null,
  handleChangeArray: (value: any) => void,
  handleSubmit: (value: any) => void,
  array: any[],
  create: string,
  fields: any,
  name: string,
  item: Field,
  className?: string,
  blocks?:{ label: string, block: string }[]
}) {
  function addObject(block?: string, index?: number) {
    const data : any = {};
    fields.filter((f: any) => f.block === block || !block).forEach((f: any) => {
      data[f.name] = '';
      if (f.type === 'media') {
        data[f.name] = null;
      }
      if (f.type === 'links') {
        data[f.name] = [];
      }
    });
    if (typeof index === 'number') {
      const updatedArray = [...array];
      updatedArray.splice(index + 1, 0, data);
      handleSubmit({ [name]: updatedArray });
    } else {
      handleSubmit({ [name]: [...array, data] });
    }
  }

  const getBlockFields = useCallback((block: any) => {
    const keysBlock = Object.keys(block);
    return fields.filter((field: Field) => keysBlock.includes(field.name));
  }, [array, fields]);

  return (
    <div className={styles.pages}>
      {label && <h2>{label}</h2>}
      <div className={styles.list}>
        {array.map((it, i) => (
          <Item
              key={`${name}-${i}`}
              id={`${name}-${i}`}
              item={it}
              fields={Array.isArray(blocks)
                ? getBlockFields(it)
                : fields
              }
              array={array}
              label={item?.label}
              className={className}
              handleChangeArray={handleChangeArray}
              handleSubmit={handleSubmit}
              index={i}
              blocks={blocks}
              addObject={addObject}
              parentName={name}
            />
        ))}
      </div>
      {blocks && blocks?.length > 0 ? (
        <div className={styles.buttonBlocks}>
          <p>Ajouter</p>
          <div className={styles['block-list']}>
          {blocks.map((d) => (
            <button
              key={`button-${d.block}`}
              onClick={() => addObject(d.block)}
              className={styles.btn}
            >
              {d.label}
            </button>
          ))}
          </div>
        </div>
      ) : (
        <button
        onClick={() => addObject()}
        className={styles.btn}
      >
        {create}
      </button>
      )}
    </div>
  );
}
