import { Dispatch } from 'redux';
import {
  getData,
  postData,
  putData,
  deleteData,
} from './index';

import {
  TESTIMONIALS_GET,
  TESTIMONIAL_GET,
  TESTIMONIAL_POST,
  TESTIMONIAL_PUT,
  TESTIMONIAL_DELETE,
  TESTIMONIAL_LOADING,
  TESTIMONIAL_ERROR,
  SET_TOAST,
} from './types';

const getTestimonial = async (dispatch: Dispatch, id: string) => {
  const url = `/testimonial/${id}`;
  dispatch({
    type: TESTIMONIAL_LOADING,
    payload: TESTIMONIAL_GET,
  });
  dispatch({
    type: TESTIMONIAL_GET,
    payload: null,
  });
  const response = await getData(TESTIMONIAL_ERROR, url, dispatch, true);
  if (response.data?.testimonial) {
    dispatch({
      type: TESTIMONIAL_GET,
      payload: response.data?.testimonial,
    });
  }
};

const getTestimonials = async (dispatch: Dispatch, params = {}) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/testimonial${queryString ? `?${queryString}` : ''}`;
  dispatch({
    type: TESTIMONIAL_LOADING,
    payload: TESTIMONIALS_GET,
  });
  const response = await getData(TESTIMONIAL_ERROR, url, dispatch, true);
  if (response.data?.testimonials) {
    dispatch({
      type: TESTIMONIALS_GET,
      payload: response.data.testimonials,
    });
  }
};

const createTestimonial = async (dispatch: Dispatch, data: any) => {
  const url = '/testimonial';
  dispatch({
    type: TESTIMONIAL_LOADING,
    payload: TESTIMONIAL_POST,
  });
  const response: any = await postData(TESTIMONIAL_ERROR, url, dispatch, data, true);
  if (response.data?.testimonial) {
    dispatch({
      type: TESTIMONIAL_POST,
      payload: response.data.testimonial,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: 'Témoignage sauvegardé',
      },
    });
  }
  return response;
};

const updateTestimonial = async (dispatch: Dispatch, data: any) => {
  const { _id, ...updatedPage } = data;
  const url = `/testimonial/${_id}`;
  dispatch({
    type: TESTIMONIAL_LOADING,
    payload: `${TESTIMONIAL_PUT}-${_id}`,
  });
  const response = await putData(TESTIMONIAL_ERROR, url, dispatch, updatedPage, true);
  if (response.data?.testimonial) {
    dispatch({
      type: TESTIMONIAL_PUT,
      payload: response.data.testimonial,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: 'Témoignage sauvegardé',
      },
    });
  }
  return response;
};

const deleteTestimonial = async (dispatch: Dispatch, data: any) => {
  const url = `/testimonial/${data._id}`;
  const response: any = await deleteData(TESTIMONIAL_ERROR, url, dispatch, true);
  if (response.status === 200) {
    dispatch({
      type: TESTIMONIAL_DELETE,
      payload: data._id,
    });
  }
  return response;
};

export {
  getTestimonial,
  getTestimonials,
  createTestimonial,
  updateTestimonial,
  deleteTestimonial,
};
