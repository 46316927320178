import {
  PAGES_GET,
  PAGE_GET,
  PAGE_LIST_GET,
  PAGE_POST,
  PAGE_PUT,
  PAGE_DELETE,
  PAGE_LOADING,
  PAGE_ERROR,
  SET_PAGES_FILTERS,
  PAGE_RESET,
} from '../actions/types';
import { ActionReducer } from '../types';
import { IFilters } from '../types/page';

interface PageState {
  page: any | null,
  pages: any[],
  list: any,
  isLoading: string[],
  error: string | null,
  changedAt: number,
  filters: IFilters,
}

const DEFAULT_STATE: PageState = {
  page: null,
  pages: [],
  list: {},
  isLoading: [],
  error: null,
  filters: {
    search: '',
    sort: 'filtered',
    sortDir: 'desc',
  },
  changedAt: Date.now(),
};

const pageReducer = (state = DEFAULT_STATE, action: ActionReducer) => {
  let updatedState = {};
  const updatePages = state.pages || [];
  const pageIndex = (updatePages || []).findIndex(
    (p: any) => p._id === action.payload?.page?._id,
  );
  switch (action.type) {
    case PAGE_RESET:
      updatedState = {
        ...state,
        page: null,
        isLoading: state.isLoading?.filter((d) => d !== PAGE_GET),
        error: null,
      };
      break;
    case PAGE_GET:
      updatedState = {
        ...state,
        page: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== PAGE_GET),
        error: null,
      };
      break;
    case PAGES_GET:
      updatedState = {
        ...state,
        page: null,
        pages: action.payload.pages,
        isLoading: state.isLoading?.filter((d) => d !== PAGES_GET),
        error: null,
      };
      break;
    case PAGE_LIST_GET:
      updatedState = {
        ...state,
        list: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== PAGE_LIST_GET),
        error: null,
      };
      break;
    case PAGE_POST:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        page: action.payload,
        pages: [action.payload, ...state.pages].sort(
          (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
        ),
        isLoading: state.isLoading?.filter((d) => d !== PAGE_POST),
        error: null,
      };
      break;
    case PAGE_DELETE:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        pages: state.pages.filter((page) => page._id !== action.payload),
        isLoading: state.isLoading?.filter((d) => d !== PAGE_DELETE),
        error: null,
      };
      break;
    case PAGE_PUT:
      if (typeof pageIndex === 'number') {
        updatePages[pageIndex] = action.payload.page;
      }
      updatedState = {
        ...state,
        page: action.payload.page,
        pages: updatePages.sort(
          (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
        ),
        changedAt: Date.now(),
        isLoading: state.isLoading?.filter(
          (d) => d !== `PAGE_PUT-${action.payload.page._id}`,
        ),
        error: null,
      };
      break;
    case PAGE_LOADING:
      updatedState = {
        ...state,
        error: null,
        isLoading: [...state.isLoading, action.payload],
      };
      break;
    case SET_PAGES_FILTERS:
      updatedState = {
        ...state,
        filters: action.payload,
      };
      break;
    case PAGE_ERROR:
      // eslint-disable-next-line no-case-declarations
      let message = action?.payload?.data?.error;
      if (message === 'That email address is already in use.') {
        message = 'Cette adresse email est déjà associée à un compte';
      } else if (message === 'Unauthorized to create an page with this role') {
        message = 'Vous n\'êtes pas autoriser à assigner ce rôle';
      }
      updatedState = { ...state, error: message, isLoading: [] };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default pageReducer;
