import {
  TESTIMONIALS_GET,
  TESTIMONIAL_GET,
  TESTIMONIAL_POST,
  TESTIMONIAL_PUT,
  TESTIMONIAL_DELETE,
  TESTIMONIAL_LOADING,
  TESTIMONIAL_ERROR,
  TESTIMONIAL_RESET,
  SET_TESTIMONIAL_FILTERS,
} from '../actions/types';
import { ActionReducer } from '../types';
import { IFilters } from '../types/page';

interface TestimonialsState {
  testimonial: any | null,
  testimonials: any[],
  list: any,
  isLoading: string[],
  error: string | null,
  filters: IFilters,
}

const DEFAULT_STATE: TestimonialsState = {
  testimonial: null,
  testimonials: [],
  list: {},
  isLoading: [],
  error: null,
  filters: {
    search: '',
    sort: 'position',
    sortDir: 'asc',
  },
};

const testimonialsReducer = (state = DEFAULT_STATE, action: ActionReducer) => {
  let updatedState = {};
  const updateTestimonials = state.testimonials || [];
  const testimonialIndex = (updateTestimonials || []).findIndex(
    (t: any) => t._id === action.payload?._id,
  );
  switch (action.type) {
    case SET_TESTIMONIAL_FILTERS:
      updatedState = {
        ...state,
        filters: action.payload,
      };
      break;
    case TESTIMONIAL_RESET:
      updatedState = {
        ...state,
        TESTIMONIAL: null,
        isLoading: state.isLoading?.filter((d) => d !== TESTIMONIAL_GET),
        error: null,
      };
      break;
    case TESTIMONIAL_GET:
      updatedState = {
        ...state,
        testimonial: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== TESTIMONIAL_GET),
        error: null,
      };
      break;
    case TESTIMONIALS_GET:
      updatedState = {
        ...state,
        testimonial: null,
        testimonials: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== TESTIMONIALS_GET),
        error: null,
      };
      break;
    case TESTIMONIAL_POST:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        testimonial: action.payload,
        testimonials: [action.payload, ...state.testimonials].sort(
          (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
        ),
        isLoading: state.isLoading?.filter((d) => d !== TESTIMONIAL_POST),
        error: null,
      };
      break;
    case TESTIMONIAL_DELETE:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        testimonials: state.testimonials.filter((t) => t._id !== action.payload),
        isLoading: state.isLoading?.filter((d) => d !== TESTIMONIAL_DELETE),
        error: null,
      };
      break;
    case TESTIMONIAL_PUT:
      if (typeof testimonialIndex === 'number') {
        updateTestimonials[testimonialIndex] = action.payload;
      }
      updatedState = {
        ...state,
        testimonial: action.payload,
        testimonials: updateTestimonials.sort(
          (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
        ),
        changedAt: Date.now(),
        isLoading: state.isLoading?.filter(
          (d) => d !== `TESTIMONIAL_PUT-${action.payload._id}`,
        ),
        error: null,
      };
      break;
    case TESTIMONIAL_LOADING:
      updatedState = {
        ...state,
        error: null,
        isLoading: [...state.isLoading, action.payload],
      };
      break;
    case TESTIMONIAL_ERROR:
      // eslint-disable-next-line no-case-declarations
      let message = action?.payload?.data?.error;
      if (message === 'That email address is already in use.') {
        message = 'Cette adresse email est déjà associée à un compte';
      } else if (message === 'Unauthorized to create an TESTIMONIAL with this role') {
        message = 'Vous n\'êtes pas autoriser à assigner ce rôle';
      }
      updatedState = { ...state, error: message, isLoading: [] };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default testimonialsReducer;
