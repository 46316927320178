import { combineReducers, createStore } from 'redux';
import authReducer from './authReducer';
import pagesReducer from './pagesReducer';
import testimonialsReducer from './testimonialsReducer';
import eventsReducer from './eventsReducer';

const rootReducer = combineReducers({
  authReducer,
  pagesReducer,
  testimonialsReducer,
  eventsReducer,
});

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;
