import { Dispatch } from 'redux';
import {
  getData,
  postData,
  putData,
  deleteData,
} from './index';

import {
  EVENTS_GET,
  EVENT_GET,
  EVENT_POST,
  EVENT_PUT,
  EVENT_DELETE,
  EVENT_LOADING,
  EVENT_ERROR,
  SET_TOAST,
} from './types';

const getEvent = async (dispatch: Dispatch, id: string) => {
  const url = `/event/${id}`;
  dispatch({
    type: EVENT_LOADING,
    payload: EVENT_GET,
  });
  dispatch({
    type: EVENT_GET,
    payload: null,
  });
  const response = await getData(EVENT_ERROR, url, dispatch, true);
  if (response.data?.event) {
    dispatch({
      type: EVENT_GET,
      payload: response.data?.event,
    });
  }
};

const getEvents = async (dispatch: Dispatch, params = {}) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/event${queryString ? `?${queryString}` : ''}`;
  dispatch({
    type: EVENT_LOADING,
    payload: EVENTS_GET,
  });
  const response = await getData(EVENT_ERROR, url, dispatch, true);
  if (response.data?.events) {
    dispatch({
      type: EVENTS_GET,
      payload: response.data.events,
    });
  }
};

const createEvent = async (dispatch: Dispatch, data: any) => {
  const url = '/event';
  dispatch({
    type: EVENT_LOADING,
    payload: EVENT_POST,
  });
  const response: any = await postData(EVENT_ERROR, url, dispatch, data, true);
  if (response.data?.event) {
    dispatch({
      type: EVENT_POST,
      payload: response.data.event,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: 'Evénement sauvegardé',
      },
    });
  }
  return response;
};

const updateEvent = async (dispatch: Dispatch, data: any) => {
  const { _id, ...updatedPage } = data;
  const url = `/event/${_id}`;
  dispatch({
    type: EVENT_LOADING,
    payload: `${EVENT_PUT}-${_id}`,
  });
  const response = await putData(EVENT_ERROR, url, dispatch, updatedPage, true);
  if (response.data?.event) {
    dispatch({
      type: EVENT_PUT,
      payload: response.data.event,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: 'Evénement sauvegardé',
      },
    });
  }
  return response;
};

const deleteEvent = async (dispatch: Dispatch, data: any) => {
  const url = `/event/${data._id}`;
  const response: any = await deleteData(EVENT_ERROR, url, dispatch, true);
  if (response.status === 200) {
    dispatch({
      type: EVENT_DELETE,
      payload: data._id,
    });
  }
  return response;
};

export {
  getEvent,
  getEvents,
  createEvent,
  updateEvent,
  deleteEvent,
};
