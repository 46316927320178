import logo from './logo.svg';

type Config = {
  name: string,
  logo: string,
  lang: string,
};

const siteConfig : Config = {
  name: 'Bakasable admin',
  logo,
  lang: 'fr',
};

export default siteConfig;
