import React, { useEffect, useState, useTransition } from 'react';
import { BiSearchAlt } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getEvents } from '../../actions/events';
import { SET_EVENT_FILTERS } from '../../actions/types';
import Loader from '../../components/Loader';
import SortItem, { ISortItem } from '../../lib/SortItem/SortItem';
import { IFilters } from '../../types/page';
import { IEvent } from '../../types/event';

import styles from './eventsList.module.scss';

const EventsList = () => {
  const dispatch = useDispatch();
  const [isPending, startTransition] = useTransition();
  const [eventsFiltered, setEventsFiltered] = useState<IEvent[]>([]);
  const { events, filters } = useSelector((store: any) => store).eventsReducer;
  const { search, sort, sortDir } = filters;

  const sortItems : ISortItem[] = [
    { value: 'name', label: 'Nom' || '' },
    { value: 'position', label: 'position' || '' },
    { value: 'isPublished', label: 'Status' || '' },
  ];

  function handleChangeFilters(obj: IFilters) {
    dispatch({
      type: SET_EVENT_FILTERS,
      payload: { ...filters, ...obj },
    });
  }

  useEffect(() => {
    getEvents(dispatch);
  }, []);

  useEffect(() => {
    if (events) {
      startTransition(() => {
        let filtered = [...events.filter(
          (p :IEvent) => p.name.toLowerCase().includes(search.toLowerCase()),
        )];
        switch (sort) {
          case 'name':
            filtered = filtered.sort(
              (a, b) => a.name.localeCompare(b.name),
            );
            break;
          case 'isPublished':
            filtered = filtered.sort(
              (a, b) => b.isPublished - a.isPublished,
            );
            break;
          default:
            filtered = filtered.sort(
              (a, b) => b.position - a.position,
            );
            break;
        }
        if (sortDir === 'asc') {
          filtered = filtered.reverse();
        }
        setEventsFiltered(filtered);
      });
    }
  }, [events, search, sort, sortDir]);

  return (
    <div className={styles.eventsList}>
      <header>
        <h1>Événements</h1>
        <div className={styles.create}>
          <div className={styles.search}>
            <input
              value={search}
              onChange={(e) => handleChangeFilters({ search: e.target.value })}
            />
            <BiSearchAlt />
          </div>
          <NavLink className={styles.btn} to={'/event/create'}>Ajouter un événement</NavLink>
        </div>
      </header>
      <div className={styles.list}>
        <div className={`${styles.row} ${styles.sort}`}>
          {sortItems.map((item) => <SortItem
            key={item.value}
            item={item}
            isAsc={!!(item.value === filters.sort && filters.sortDir === 'asc') }
            styles={styles}
            setActiveSort={(d) => handleChangeFilters({
              sort: d.value,
              sortDir: filters.sortDir === 'desc' ? 'asc' : 'desc',
            })}
          />)}
        </div>
      </div>
      {!isPending && eventsFiltered?.length > 0 && (
        <ul className={styles.list}>
          {eventsFiltered.map((event: IEvent) => (
            <li key={`event-${event._id}`}>
              <NavLink className={styles.row} to={`/event/${event._id}`}>
                <div className={styles.col}>
                  {event.name}
                </div>
                <div className={styles.col}>
                  {event.position}
                </div>
                <div className={styles.col}>
                  {event.isPublished && <span className={styles.success}>Publié</span>}
                </div>
              </NavLink>
            </li>
          ))}
        </ul>
      )}
      {isPending
        && <div className={styles.loader}>
          <Loader />
        </div>
      }
    </div>
  );
};

export default EventsList;
