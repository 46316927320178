import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { MdLogout } from 'react-icons/md';
import { logout } from '../../actions/auth';
import styles from './sidebar.module.scss';
import siteConfig from '../../config/config';
import { getPageList } from '../../actions/pages';
import { PageTemplate } from '../../types/page';

const Sidebar = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((store: any) => store).authReducer;
  const { list } = useSelector((store: any) => store).pagesReducer;

  const templates : PageTemplate[] = list?.templates || [];

  useEffect(() => {
    getPageList(dispatch);
  }, []);

  const renderProfilePicture = () => {
    const token = localStorage.getItem('token')?.replace('JWT ', '');
    const url = `${process.env.REACT_APP_API_URL}/files/public/${user?.picture?.path}?token=${token}`;
    return (
      <div
        className={styles.profilePicture}
        style={user?.picture?.path ? { backgroundImage: `url(${url})` } : {}}
      >
        {!user?.picture?.path && (
          <p>
            {user.profile.firstName[0]}
            {user.profile.lastName[0]}
          </p>
        )}
      </div>
    );
  };

  return (
    <div className={styles.sidebar}>
      <div className={styles.logo}>
        <img src={siteConfig.logo} alt="logo" />
      </div>
      <div className={styles.navigation}>
        <ul>
          {templates.filter((t) => t.isNav).map((t) => (
            <li key={t.type}>
              <NavLink to={`pages/${t.type}`} className={({ isActive }) => (isActive ? styles.active : '')}>{t.label}</NavLink>
            </li>
          ))}
          <li>
            <NavLink to={'testimonial'} className={({ isActive }) => (isActive ? styles.active : '')}>Témoignages</NavLink>
          </li>
          <li>
            <NavLink to={'event'} className={({ isActive }) => (isActive ? styles.active : '')}>Événements</NavLink>
          </li>
        </ul>
      </div>
      <div className={styles.bottom}>
        {renderProfilePicture()}
        <div className={styles.logout}>
          <button onClick={() => logout(dispatch)}><MdLogout size={24} /></button>
          <p onClick={() => logout(dispatch)}>
            <span>Déconnexion</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
