import React, { useEffect, useState, useTransition } from 'react';
import { BiSearchAlt } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getTestimonials } from '../../actions/testimonials';
import { SET_TESTIMONIAL_FILTERS } from '../../actions/types';
import Loader from '../../components/Loader';
import SortItem, { ISortItem } from '../../lib/SortItem/SortItem';
import { IFilters } from '../../types/page';
import { ITestimonial } from '../../types/testimonial';

import styles from './testimonialsList.module.scss';

const TestimonialsList = () => {
  const dispatch = useDispatch();
  const [isPending, startTransition] = useTransition();
  const [testimonialsFiltered, settestimonialsFiltered] = useState<ITestimonial[]>([]);
  const { testimonials, filters } = useSelector((store: any) => store).testimonialsReducer;
  const { search, sort, sortDir } = filters;

  const sortItems : ISortItem[] = [
    { value: 'title', label: 'Client' || '' },
    { value: 'position', label: 'position' || '' },
    { value: 'isPublished', label: 'Status' || '' },
  ];

  function handleChangeFilters(obj: IFilters) {
    dispatch({
      type: SET_TESTIMONIAL_FILTERS,
      payload: { ...filters, ...obj },
    });
  }

  useEffect(() => {
    getTestimonials(dispatch);
  }, []);

  useEffect(() => {
    if (testimonials) {
      startTransition(() => {
        let filtered = [...testimonials.filter(
          (p :ITestimonial) => p.company.toLowerCase().includes(search.toLowerCase()),
        )];
        switch (sort) {
          case 'company':
            filtered = filtered.sort(
              (a, b) => a.company.localeCompare(b.company),
            );
            break;
          case 'isPublished':
            filtered = filtered.sort(
              (a, b) => b.isPublished - a.isPublished,
            );
            break;
          default:
            filtered = filtered.sort(
              (a, b) => b.position - a.position,
            );
            break;
        }
        if (sortDir === 'asc') {
          filtered = filtered.reverse();
        }
        settestimonialsFiltered(filtered);
      });
    }
  }, [testimonials, search, sort, sortDir]);

  return (
    <div className={styles.testimonialsList}>
      <header>
        <h1>Témoignages</h1>
        <div className={styles.create}>
          <div className={styles.search}>
            <input
              value={search}
              onChange={(e) => handleChangeFilters({ search: e.target.value })}
            />
            <BiSearchAlt />
          </div>
          <NavLink className={styles.btn} to={'/testimonial/create'}>Ajouter un témoignage</NavLink>
        </div>
      </header>
      <div className={styles.list}>
        <div className={`${styles.row} ${styles.sort}`}>
          {sortItems.map((item) => <SortItem
            key={item.value}
            item={item}
            isAsc={!!(item.value === filters.sort && filters.sortDir === 'asc') }
            styles={styles}
            setActiveSort={(d) => handleChangeFilters({
              sort: d.value,
              sortDir: filters.sortDir === 'desc' ? 'asc' : 'desc',
            })}
          />)}
        </div>
      </div>
      {!isPending && testimonialsFiltered?.length > 0 && (
        <ul className={styles.list}>
          {testimonialsFiltered.map((testimonial: ITestimonial) => (
            <li key={`testimonial-${testimonial._id}`}>
              <NavLink className={styles.row} to={`/testimonial/${testimonial._id}`}>
                <div className={styles.col}>
                  {testimonial.company}
                </div>
                <div className={styles.col}>
                  {testimonial.position}
                </div>
                <div className={styles.col}>
                  {testimonial.isPublished && <span className={styles.success}>Publié</span>}
                </div>
              </NavLink>
            </li>
          ))}
        </ul>
      )}
      {isPending
        && <div className={styles.loader}>
          <Loader />
        </div>
      }
    </div>
  );
};

export default TestimonialsList;
