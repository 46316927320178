import React, { useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { forgotPassword } from '../../actions/auth';
import EmailResetPwdForm from '../../components/form/AuthForm/EmailResetPwdForm';
import siteConfig from '../../config/config';
import styles from './forgot-password.module.scss';

const ForgotPassword = () => {
  const authReducer = useSelector((d: any) => d.authReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [message, setMessage] = useState<string>();

  useEffect(() => {
    if (authReducer.user?._id) navigate('/pages/project');
  }, [authReducer.user]);

  function submitForgot(data: FieldValues) {
    forgotPassword(dispatch, data).then((res: any) => {
      if (res?.status === 200) {
        setMessage(
          'Un email permettant de changer votre mot de passe vous a été envoyé.',
        );
      }
    });
  }

  return (
    <div className={styles['forgot-password']}>
      <div className={styles.top}>
        <div className={styles.container}>
          <img src={siteConfig.logo} alt="logo sawa" />
        </div>
      </div>
      <div className={styles.container}>
        <h3>Mot de passe oublié</h3>
        <div className={styles.form}>
          <p>
            Après validation vous recevrez un email vous permettant de changer
            de mot de passe.
          </p>
          <EmailResetPwdForm
            submit={submitForgot}
            signinLink="/"
            succeedMessage={message}
            errorMessage={authReducer.error}
          />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
