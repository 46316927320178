import React, {
  useEffect,
  useRef, useState,
} from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import Editor from '@monaco-editor/react';
import styles from './Code.module.scss';

interface CodeProps extends UseControllerProps {
  name: string,
  rules: {},
  widthLabel?: string,
  inline?: boolean,
  disabled?: boolean,
  label?: string | null,
  placeholder?: string,
  defaultValue?: string,
  maxlength?: number,
  className?: string,
}

const CodeEditor = ({
  name,
  control,
  rules = {},
  inline = false,
  disabled = false,
  label = null,
  defaultValue,
  widthLabel,
  className,
}: CodeProps) => {
  const [height, setHeight] = useState<any>(19);
  const codeRef = useRef<HTMLDivElement>(null);

  const {
    field: {
      value = '',
      onChange,
    },
    formState: { isValid },
  } = useController({
    control,
    name,
    rules,
    defaultValue,
  });

  function autosize(val : string | undefined) {
    if (val) {
      const countOfLines = val?.split('\n')?.length;
      const currentHeight = countOfLines * 19;
      if (currentHeight) {
        return setHeight(currentHeight);
      }
    }
    return setHeight(19);
  }

  function getClassName() {
    let classValue = styles['container-code'];
    if (className) {
      classValue += ` ${styles[className]}`;
    }
    if (inline) {
      classValue += ` ${styles.inline}`;
    }
    if (disabled) {
      classValue += ` ${styles.disabled}`;
    }
    return classValue;
  }

  function handleChangeValue(val: string | undefined) {
    if (!isValid && val === '\n') {
      onChange('');
      return null;
    }
    onChange(val);
    return null;
  }

  useEffect(() => {
    if (value) {
      autosize(value);
    }
  }, [value]);

  return (
      <div
        ref={codeRef}
        className={getClassName()}
      >
        {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
        <div className={`${styles.container}`}>
        <Editor
          height={height ? `${height}px` : '160px'}
          language="javascript"
          theme="vs-dark"
          value={value}
          onChange={(val: string | undefined) => handleChangeValue(val)}
          options={{
            formatOnType: true,
            minimap: { enabled: false },
            scrollBeyondLastLine: false,
            renderValidationDecorations: 'off',
          }}
        />
        </div>
      </div>
  );
};

export default CodeEditor;
