import { Dispatch } from 'redux';
import {
  getData,
  postData,
  putData,
  deleteData,
} from './index';

import {
  PAGES_GET,
  PAGE_GET,
  PAGE_LIST_GET,
  PAGE_POST,
  PAGE_PUT,
  PAGE_DELETE,
  PAGE_LOADING,
  PAGE_ERROR,
  SET_TOAST,
} from './types';

const getPage = async (dispatch: Dispatch, id: string) => {
  const url = `/pages/${id}`;
  dispatch({
    type: PAGE_LOADING,
    payload: PAGE_GET,
  });
  dispatch({
    type: PAGE_GET,
    payload: null,
  });
  const response = await getData(PAGE_ERROR, url, dispatch, true);
  if (response.data?.page) {
    dispatch({
      type: PAGE_GET,
      payload: response.data?.page,
    });
  }
};

const getPageList = async (dispatch: Dispatch) => {
  const url = '/pages/list';
  dispatch({
    type: PAGE_LOADING,
    payload: PAGE_LIST_GET,
  });
  const response = await getData(PAGE_ERROR, url, dispatch, true);
  if (response.data?.list) {
    dispatch({
      type: PAGE_LIST_GET,
      payload: response.data?.list,
    });
  }
};

const getPages = async (dispatch: Dispatch, params = {}) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/pages${queryString ? `?${queryString}` : ''}`;
  dispatch({
    type: PAGE_LOADING,
    payload: PAGES_GET,
  });
  const response = await getData(PAGE_ERROR, url, dispatch, true);
  if (response.data?.pages) {
    dispatch({
      type: PAGES_GET,
      payload: response.data,
    });
  }
};

const createPage = async (dispatch: Dispatch, data: any) => {
  const url = '/pages';
  dispatch({
    type: PAGE_LOADING,
    payload: PAGE_POST,
  });
  const response: any = await postData(PAGE_ERROR, url, dispatch, data, true);
  if (response.data?.page) {
    dispatch({
      type: PAGE_POST,
      payload: response.data.page,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: 'Page sauvegardé',
      },
    });
  }
  return response;
};

const updatePage = async (dispatch: Dispatch, data: any) => {
  const { _id, ...updatedPage } = data;
  const url = `/pages/${_id}`;
  dispatch({
    type: PAGE_LOADING,
    payload: `${PAGE_PUT}-${_id}`,
  });

  // eslint-disable-next-line no-restricted-syntax
  for (const [key] of Object.entries(updatedPage.content)) {
    const images = ['cover', 'bottom-img', 'picture'];
    const found = images.find((s) => s === key);
    if (found) {
      updatedPage.content[found] = updatedPage.content[found]?._id || updatedPage.content[found];
    }
    if (key === 'blocks') {
      updatedPage.content[key].forEach((b: any, i: number) => {
        if (b.image?._id) {
          updatedPage.content[key][i].image = b.image?._id;
        }
        if (b?.links?.length > 0) {
          b.links.forEach((l: any, index: number) => {
            updatedPage.content[key][i].links[index].img = l.img?._id || l.img;
          });
        }
      });
    }
  }
  const response = await putData(PAGE_ERROR, url, dispatch, updatedPage, true);
  if (response.data?.page) {
    dispatch({
      type: PAGE_PUT,
      payload: response.data,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: 'Page sauvegardé',
      },
    });
  }
  return response;
};

const deletePage = async (dispatch: Dispatch, data: any) => {
  const url = `/pages/${data._id}`;
  const response: any = await deleteData(PAGE_ERROR, url, dispatch, true);
  if (response.status === 200) {
    dispatch({
      type: PAGE_DELETE,
      payload: data._id,
    });
  }
  return response;
};

export {
  getPage,
  getPages,
  getPageList,
  createPage,
  updatePage,
  deletePage,
};
