import {
  EVENTS_GET,
  EVENT_GET,
  EVENT_POST,
  EVENT_PUT,
  EVENT_DELETE,
  EVENT_LOADING,
  EVENT_ERROR,
  EVENT_RESET,
  SET_EVENT_FILTERS,
} from '../actions/types';
import { ActionReducer } from '../types';
import { IFilters } from '../types/page';

interface EventsState {
  event: any | null,
  events: any[],
  list: any,
  isLoading: string[],
  error: string | null,
  filters: IFilters,
}

const DEFAULT_STATE: EventsState = {
  event: null,
  events: [],
  list: {},
  isLoading: [],
  error: null,
  filters: {
    search: '',
    sort: 'position',
    sortDir: 'asc',
  },
};

const eventsReducer = (state = DEFAULT_STATE, action: ActionReducer) => {
  let updatedState = {};
  const updateEvents = state.events || [];
  const eventIndex = (updateEvents || []).findIndex(
    (t: any) => t._id === action.payload?._id,
  );
  switch (action.type) {
    case SET_EVENT_FILTERS:
      updatedState = {
        ...state,
        filters: action.payload,
      };
      break;
    case EVENT_RESET:
      updatedState = {
        ...state,
        event: null,
        isLoading: state.isLoading?.filter((d) => d !== EVENT_RESET),
        error: null,
      };
      break;
    case EVENT_GET:
      updatedState = {
        ...state,
        event: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== EVENT_GET),
        error: null,
      };
      break;
    case EVENTS_GET:
      updatedState = {
        ...state,
        event: null,
        events: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== EVENTS_GET),
        error: null,
      };
      break;
    case EVENT_POST:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        event: action.payload,
        events: [action.payload, ...state.events].sort(
          (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
        ),
        isLoading: state.isLoading?.filter((d) => d !== EVENT_POST),
        error: null,
      };
      break;
    case EVENT_DELETE:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        events: state.events.filter((t) => t._id !== action.payload),
        error: null,
      };
      break;
    case EVENT_PUT:
      if (typeof eventIndex === 'number') {
        updateEvents[eventIndex] = action.payload;
      }
      updatedState = {
        ...state,
        event: action.payload,
        events: updateEvents.sort(
          (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
        ),
        changedAt: Date.now(),
        isLoading: state.isLoading?.filter(
          (d) => d !== `EVENT_PUT-${action.payload._id}`,
        ),
        error: null,
      };
      break;
    case EVENT_LOADING:
      updatedState = {
        ...state,
        error: null,
        isLoading: [...state.isLoading, action.payload],
      };
      break;
    case EVENT_ERROR:
      // eslint-disable-next-line no-case-declarations
      const message = action?.payload?.data?.error;
      updatedState = { ...state, error: message, isLoading: [] };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default eventsReducer;
