import React, {
  useEffect, useState, useRef, useId,
} from 'react';
import axios from 'axios';
import { FieldValues, useForm } from 'react-hook-form';
import { RiDeleteBin2Fill, RiCloseLine, RiSave2Fill } from 'react-icons/ri';
import { FaPenNib } from 'react-icons/fa';
// Contect & actions
import { useDispatch } from 'react-redux';
import { deleteFile, updateFile } from '../../../actions/file';

// Styles
import styles from './media.module.scss';
import { FileType } from '../../../types/file';
import InputText from '../InputText';

const mimetypes = 'image/png, image/jpeg, image/gif, video/mp4, audio/mpeg3, audio/mp3 ,audio/mpeg';

function InputMedia({
  label,
  name,
  media,
  // handleChange,
  submit,
}:{
  index?: number | null,
  name: string,
  type?: 'image' | 'video',
  label?: string | null,
  media: FileType | null,
  handleChange?: (value: any) => void,
  submit: (value: any, key: string, fileId: string | null) => void,
}) {
  const inputId = useId();
  const isAudio = media?.mimetype === 'audio/mp3' || media?.mimetype === 'audio/mpeg';
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    setFocus,
  } = useForm<FieldValues>();
  const inputRef = useRef<null>(null);
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  useEffect(() => {
    if (media) {
      reset(media);
    }
  }, [media]);

  useEffect(() => {
    if (isEdit) {
      setFocus('alt');
    }
  }, [isEdit]);

  async function handleChangeFile(e : any) {
    const fileData = e.target.files[0];
    if (media?._id) {
      deleteFile(dispatch, media._id);
      submit({ [name]: null }, name, null);
    }
    const formData = new FormData();
    formData.append('file', fileData);
    formData.append('name', fileData.name);
    formData.append('alt', fileData.name.split('.')[0]);
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token');
      const config: any = {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'multipart/form-data',
        },
      };
      axios.post(`${process.env.REACT_APP_API_URL}/files`, formData, config)
        .then((response : any) => {
          resolve(response.data);
          submit({ [name]: response.data.file._id }, name, response.data.file._id);
        })
        .catch((error: any) => {
          reject(error);
          console.log(error);
        });
    });
  }

  function deleteCurrentFile() {
    if (media?._id) {
      deleteFile(dispatch, media._id);
      submit({ [name]: null }, name, null);
      // handleChange(null);
    }
  }

  function editmedia() {
    const values = getValues();
    setIsEdit(!isEdit);
    updateFile(dispatch, values);
  }

  return (
    <div className={styles.container}>
      {label && <label>{label}</label>}
      <div className={`${styles.img} ${isAudio ? styles.audio : ''}`}>
        {media?.path
          ? <>
            {media.mimetype === 'video/mp4'
              && <video controls>
                <source
                  src={`${process.env.REACT_APP_API_URL}/public/${media.path}`}
                  type={media.mimetype}>
                </source>
              </video>
            }
           {(media.mimetype === 'image/png' || media.mimetype === 'image/jpeg' || media.mimetype === 'image/gif')
              && <img
                  src={`${process.env.REACT_APP_API_URL}/public/${media.path}`} alt={media.alt}
                />
            }
            {isAudio
              && <audio controls>
                <source src={`${process.env.REACT_APP_API_URL}/public/${media.path}`} type="audio/mp3"/>
              </audio>
            }
            {!isEdit
              && <>
              <div className={styles['btn-container']}>
                <button
                  type="button"
                  onClick={() => setIsEdit(!isEdit)}
                  >
                  <FaPenNib />
                </button>
                <button
                  type="button"
                  onClick={() => deleteCurrentFile()}
                  >
                  <RiDeleteBin2Fill />
                </button>
              </div>
              </>
            }
          </>
          : <div className={styles.input}>
            <label htmlFor={inputId}>Choisir un fichier</label>
            <input
              id={inputId}
              type='file'
              accept={mimetypes}
              onChange={(e) => handleChangeFile(e)}
              ref={inputRef}
            />
          </div>
        }
        {media?.alt
          && <div className={`${styles.edit} ${isEdit ? styles.active : ''}`}>
              <button
              type="button"
              onClick={() => setIsEdit(!isEdit)}
              className={styles.close}
              >
                <RiCloseLine size={30}/>
              </button>
            <div className={styles.form}>
              <div className={styles.field}>
                <InputText
                  rules={{ }}
                  label='alt'
                  name='alt'
                  control={control}
                />
              </div>
              <button
                type="button"
                onClick={handleSubmit(editmedia)}
                className={styles.submit}
                >
                <RiSave2Fill size={26}/>
              </button>
            </div>
          </div>
        }
      </div>

    </div>
  );
}

export default InputMedia;
