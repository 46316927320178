const get = (obj: Record<string, any> = {}, path: string | string[]): any => {
  const properties = Array.isArray(path) ? path : path.split('.');
  return properties.reduce((prev: any, curr: any) => prev && prev[curr], obj);
};

const set = (obj: Record<string, any> = {}, path: string | string[], value: any) => {
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
  if (!pathArray) return;
  pathArray.reduce((acc, key, i) => {
    if (acc[key] === undefined) acc[key] = {};
    if (pathArray?.length > 0 && i === pathArray.length - 1) acc[key] = value;
    return acc[key];
  }, obj);
};

export { get, set };
