// ACTIONS AUTH
export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const AUTH_GET_PROFILE = 'AUTH_GET_PROFILE';
export const AUTH_PUT_PROFILE = 'AUTH_PUT_PROFILE';
export const AUTH_UPDATE_PROFILE_PICTURE = 'AUTH_UPDATE_PROFILE_PICTURE';
export const AUTH_DELETE_PROFILE_PICTURE = 'AUTH_DELETE_PROFILE_PICTURE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SET_TOAST = 'SET_TOAST';

// ACTIONS PAGES

export const PAGES_GET = 'PAGES_GET';
export const PAGE_GET = 'PAGE_GET';
export const PAGE_RESET = 'PAGE_RESET';
export const PAGE_LIST_GET = 'PAGE_LIST_GET';
export const PAGE_POST = 'PAGE_POST';
export const PAGE_PUT = 'PAGE_PUT';
export const PAGE_DELETE = 'PAGE_DELETE';
export const PAGE_LOADING = 'PAGE_LOADING';
export const PAGE_ERROR = 'PAGE_ERROR';
export const SET_PAGES_FILTERS = 'SET_PAGES_FILTERS';

// ACTOINS TESTIMONIALS
export const TESTIMONIALS_GET = 'TESTIMONIALS_GET';
export const TESTIMONIAL_GET = 'TESTIMONIAL_GET';
export const TESTIMONIAL_RESET = 'TESTIMONIAL_RESET';
export const TESTIMONIAL_POST = 'TESTIMONIAL_POST';
export const TESTIMONIAL_PUT = 'TESTIMONIAL_PUT';
export const TESTIMONIAL_DELETE = 'TESTIMONIAL_DELETE';
export const SET_TESTIMONIAL_FILTERS = 'SET_TESTIMONIAL_FILTERS';
export const TESTIMONIAL_LOADING = 'TESTIMONIAL_LOADING';
export const TESTIMONIAL_ERROR = 'TESTIMONIAL_ERROR';

// ACTOINS EVENTS
export const EVENTS_GET = 'EVENTS_GET';
export const EVENT_GET = 'EVENT_GET';
export const EVENT_RESET = 'EVENT_RESET';
export const EVENT_POST = 'EVENT_POST';
export const EVENT_PUT = 'EVENT_PUT';
export const EVENT_DELETE = 'EVENT_DELETE';
export const SET_EVENT_FILTERS = 'SET_EVENT_FILTERS';
export const EVENT_LOADING = 'EVENT_LOADING';
export const EVENT_ERROR = 'EVENT_ERROR';

export const FILE_ERROR = 'FILE_ERROR';
