import React, {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation, useNavigate, useParams,
} from 'react-router-dom';
import { AiFillDelete } from 'react-icons/ai';
import styles from './testimonial.module.scss';
import { ErrorField, InputText, TextEditor } from '../../lib/HooksFormFields';
import {
  getTestimonial, createTestimonial, deleteTestimonial, updateTestimonial,
} from '../../actions/testimonials';
import InputNumber from '../../lib/HooksFormFields/InputNumber';

const Testimonial = () => {
  const { id } = useParams();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isSaved, setIsSaved] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { testimonial, testimonials } = useSelector((store: any) => store).testimonialsReducer;

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm<FieldValues>({
    defaultValues: {
      isPublished: false,
      position: testimonials?.length ? testimonials.length + 1 : 1,
    },
  });

  // Navigate to page when create
  useEffect(() => {
    if (!id && testimonial?._id && /create/.test(location.pathname)) {
      navigate(`/testimonial/${testimonial._id}`);
    }
  }, [testimonial?._id]);

  async function handleDelete() {
    // eslint-disable-next-line no-alert
    if (window.confirm('Veuillez confirmer la suppression du témoignage')) {
      await deleteTestimonial(dispatch, testimonial);
    }
    navigate('/testimonial');
  }

  function onSubmit(values: FieldValues) {
    if (testimonial?._id) {
      return updateTestimonial(dispatch, values);
    }
    const data = { ...values };
    return createTestimonial(dispatch, data);
  }

  useLayoutEffect(() => {
    containerRef?.current?.parentElement?.scrollTo({
      top: 0,
    });
  }, []);

  useEffect(() => {
    if (id) {
      getTestimonial(dispatch, id);
    }
  }, [id]);

  useEffect(() => {
    if (testimonial?._id) {
      reset(testimonial);
      setIsSaved(true);
    } else {
      reset({
        isPublished: false,
      });
    }
  }, [testimonial]);

  useEffect(() => {
    setIsSaved(!isDirty);
  }, [isDirty]);

  return (
    <div
      id='page'
      className={styles.testimonial}
      ref={containerRef}
    >

      <div className={styles.header}>
        <h1>{testimonial?.company || 'Nouveau témoignage'}</h1>
      </div>

      <div className={styles.submit}>
        <button className={`${styles.btn} ${!watch('isPublished') ? styles.draft : styles.success}`}
          onClick={() => setValue('isPublished', !watch('isPublished'))}
        >
          {!watch('isPublished') ? 'Brouillon' : 'Publié'}
        </button>
        <button className={`${styles.btn} ${isSaved ? styles.saved : ''}`} onClick={handleSubmit(onSubmit)}>
          Sauvegarder
        </button>
      </div>
      <div className={styles.form}>
        <div className={styles.content}>
          <div className={styles.field}>
            <InputText
              label={'Client'}
              placeholder={'client'}
              name={'company'}
              control={control}
              rules={{ required: 'Ce champs est requis' }}
            />
            {typeof errors?.company?.message === 'string' && <ErrorField message={errors.company.message}/>}
          </div>
          <div className={styles.field}>
            <TextEditor
              label={'Texte'}
              placeholder={''}
              name={'content'}
              control={control}
              rules={{ required: 'Ce champs est requis' }}
              maxlength={500}
              defaultValue={testimonial?.content || ''}
            />
            {typeof errors?.content?.message === 'string' && <ErrorField message={errors.content.message}/>}
          </div>
        </div>
        <div className={styles.meta}>
          <div className={styles.field}>
            <InputNumber
              label={'Position'}
              name={'position'}
              control={control}
              rules={{ required: 'Ce champs est requis' }}
            />
            {typeof errors?.position?.message === 'string' && <ErrorField message={errors.position.message}/>}
          </div>
          {testimonial?._id
            && <button
              className={styles.delete}
              onClick={() => handleDelete()}
            >
              <AiFillDelete />Supprimer la page
            </button>
          }
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
